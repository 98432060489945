<template>
  <div
    class="data-table data-table--selectable"
    :class="[itemsLoading ? 'data-table--loading' : '', ,]"
  >
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      :search="search"
      hide-default-footer
      hide-default-header
      class="elevation-0 custom-data-table"
      :loading="itemsLoading"
    >
      <template v-if="itemsLoading" v-slot:body>
        <template v-if="$vuetify.breakpoint.lgAndDown">
          <devices-table-skeleton-mobile-item v-for="item in 5" :key="item" />
        </template>
        <template v-else>
          <devices-table-sort-skeleton />
          <devices-table-item-skeleton />
        </template>
      </template>

      <template v-slot:no-results>
        <DataIteratorNoResultsContainer
          v-if="search"
          @clearAllFilters="$emit('clearFilters')"
          :searchTerm="search"
        />
      </template>

      <template
        v-if="!$vuetify.breakpoint.mobile && items.length"
        v-slot:header="{ isMobile, props, on }"
      >
        <table-custom-sort
          :isMobile="isMobile"
          :props="props"
          canBeSelected
          :allSelected="allSelected"
          :v-on="on"
          @selectAll="handleSelectAll"
          @update:checked-items="handleItemCheck"
          @update:sortBy="changeSortBy"
          @update:sortDesc="changeSortDesc"
        />
      </template>

      <template
        v-if="!$vuetify.breakpoint.mobile"
        v-slot:item.select="{ item }"
      >
        <div>
          <checkbox
            @change="handleItemCheck(item)"
            :checked="checkedItems.includes(item.device_id)"
          />
        </div>
      </template>

      <template v-slot:item.ip_address="{ item }">
        <b class="heading--text">
          {{ item.ip_address }}
        </b>
      </template>

      <template v-slot:item.browser="{ item }">
        <div class="d-flex align-center">
          <v-icon size="24" class="mr-2">
            {{ `$${getBrowserIcon(item.browser)}` }}
          </v-icon>
          {{ item.browser }}
        </div>
      </template>

      <template v-slot:item.device="{ item }">
        <div class="d-flex align-center">
          <v-icon size="24" class="mr-2">
            {{ `$${getDeviceIcon(item.device)}` }}
          </v-icon>
          {{ item.device }}
          <status-label
            class="ml-2"
            v-if="item.current_device"
            small
            status="primary"
            :value="$t('message.status.device.current')"
          />
        </div>
      </template>

      <template v-slot:item.last_login="{ item }">
        <template v-if="!item.session_id">{{ item.last_login }}</template>
        <div class="current-device" v-else>{{ $t('message.status.device.online') }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex actions-row">
          <v-tooltip
            v-if="item.device_id"
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                @click="showForgetDeviceModal(item)"
              >
                <v-icon>$thrash</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.device.forget', {count: 1}) }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="item.session_id"
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="showLogoutDeviceModal(item)"
              >
                <v-icon>$logout</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.device.logout') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template
        v-if="$vuetify.breakpoint.mobile"
        v-slot:item="{ item, headers }"
      >
        <devices-table-responsive-item
          :item="item"
          :itemBrowser="getBrowserIcon(item.browser)"
          :itemDevice="getDeviceIcon(item.device)"
          :headers="headers"
          :checked="checkedItems.includes(item.device_id)"
          @update:checked="handleItemCheck(item)"
          @update:check-all="handleSelectAll"
          @update:checked-items="handleItemCheck"
          @update:sortBy="changeSortBy"
          @update:sortDesc="changeSortDesc"
          @logoutDevice="showLogoutDeviceModal(item)"
          @forgetDevice="showForgetDeviceModal(item)"
        />
        <!-- <backups-table-responsive-item
          :item="item"
          :headers="headers"
          @update:checked="handleSelectItem(item)"
          :checked="checkedItems.includes(item.id)"
          v-on="$listeners"
        /> -->
      </template>

      <template v-slot:footer="{ props }" v-if="items.length">
        <div>
          <data-iterator-footer
            :props="props"
            :page="props.pagination.page"
            :numberOfPages="props.pagination.pageCount"
            :possibleItemsPerPage="possibleItemsPerPage"
            @update:itemsPerPage="changePerPage"
            @update:page="changePage"
            :listStyle="0"
            :itemsPerPage="props.pagination.itemsPerPage"
            v-on="$listeners"
            :perPageLabel="$t('table.footer.device')"
          >
          </data-iterator-footer>
        </div>
      </template>
    </v-data-table>
    <v-dialog
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
      transition="custom-dialog-transition"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
      />
    </v-dialog>
    <devices-snackbar
      :items="items"
      :checkedItems="checkedItems"
      @update:check-all="handleSelectAll"
      @mass-action-forget="
        showMassForgetDeviceModal(
          items.filter((item) => {
            return checkedItems.includes(item.device_id);
          })
        )
      "
    />
  </div>
</template>

<script>
import Api from "@/apis/Api";

import DataIteratorPageMixin from "../../../mixins/DataIteratorPageMixin";
// import CustomTableMixin from "../../../mixins/CustomTableMixin";
import CustomTablePageMixin from "../../../mixins/CustomTablePageMixin";
import DevicesActionsMixin from "../../../mixins/DevicesActionsMixin";

import DataIteratorNoResultsContainer from "../DataIteratorNoResultsContainer.vue";
import DataIteratorFooter from "../DataIteratorFooter.vue";
import TableCustomSort from "../TableCustomSort.vue";
import Checkbox from "../../buttons/Checkbox.vue";
import BasicModal from "../../modal/BasicModal.vue";
import DevicesTableResponsiveItem from "./DevicesTableResponsiveItem.vue";
import StatusLabel from "../../StatusLabel.vue";
import DevicesTableSkeletonMobileItem from "./DevicesTableSkeletonMobileItem.vue";
import DevicesTableItemSkeleton from "./DevicesTableSkeletonItem.vue";
import DevicesTableSortSkeleton from "./DevicesTableSortSkeleton.vue";
import DevicesSnackbar from "./DevicesSnackbar.vue";

//import Auth from "@/apis/Auth";

export default {
  components: {
    TableCustomSort,
    DataIteratorFooter,
    Checkbox,
    BasicModal,
    DevicesTableResponsiveItem,
    StatusLabel,
    DevicesTableSkeletonMobileItem,
    DevicesTableItemSkeleton,
    DevicesTableSortSkeleton,
    DevicesSnackbar,
    DataIteratorNoResultsContainer,
  },
  mixins: [DataIteratorPageMixin, CustomTablePageMixin, DevicesActionsMixin],
  props: {
    search: String,
  },
  computed: {
    allSelected: function () {
      return (
        this.checkedItems.length === this.items.length &&
        this.items.length !== 0
      );
    },
  },
  data() {
    return {
      modalRender: 0,
      checkedItems: [],
      itemsPerPage: 10,
      page: 1,
      possibleItemsPerPage: [[10, 20, 30]],
      itemsLoading: false,
      headers: [
        {
          text: "",
          value: "select",
          sortable: false,
        },
        {
          text: this.$t('table.header.ip'),
          value: "ip_address",
          sortable: true,
        },
        {
          text: this.$t('table.header.browser'),
          value: "browser",
          sortable: true,
        },
        {
          text: this.$t('table.header.device'),
          value: "device",
          sortable: true,
        },
        {
          text: this.$t('table.header.lastLogin'),
          value: "last_login",
          sortable: true,
        },
        {
          text: this.$t('table.header.actions'),
          value: "actions",
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    getBrowserIcon(browser) {
      if (browser.includes("Chrome")) {
        return "chromeIcon";
      } else if (browser.includes("Firefox")) {
        return "firefoxIcon";
      } else if (browser.includes("Opera")) {
        return "operaIcon";
      } else if (browser.includes("Edge")) {
        return "edgeIcon";
      } else if (browser.includes("Safari")) {
        return "safariIcon";
      } else {
        return "browserOtherIcon";
      }
    },
    getDeviceIcon(device) {
      switch (device) {
        case "Windows":
          return "windowsIcon";
        case "GNU/Linux":
          return "linuxIcon";
        case "Mac":
          return "macOsIcon";
        case "iOS":
          return "macOsIcon";
        case "Android":
          return "androidIcon";
        default:
          return "deviceOtherIcon";
      }
    },
    handleItemCheck: function (item) {
      let id = item.device_id || item.name;
      if (!this.checkedItems.includes(id)) {
        this.checkedItems.push(id);
      } else {
        const index = this.checkedItems.indexOf(id);
        this.checkedItems.splice(index, 1);
      }
    },
    handleSelectAll(data) {
      this.items.forEach((item) => {
        let id = item.device_id ? item.device_id : item.name;
        if (data) {
          if (!this.checkedItems.includes(id)) {
            this.handleItemCheck(item);
          }
        } else {
          if (this.checkedItems.includes(id)) {
            this.handleItemCheck(item);
          }
        }
      });
    },
    reloadData() {
      this.itemsLoading = true;
      Api.get("/user/devices")
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.itemsLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.current-device {
  position: relative;
  padding: 16px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background-color: var(--v-primary-base);
    top: calc(50% - 4px);
  }
}
</style>
